define('lacsso/services/modal', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Service.extend({
    modalType: 'generic-modal',
    modalOpts: null,
    modalVisible: false,
    lastScroll: null,
    closeWithOutsideClick: _ember['default'].computed.alias('modalOpts.closeWithOutsideClick'),
    toggleModal: function toggleModal() {
      var _this = this;

      var type = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];
      var opts = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      if (opts) {
        this.set('modalOpts', opts);
      }

      this.set('modalType', type);

      if (this.get('modalVisible')) {
        this.set('modalVisible', false);
        this.set('modalOpts', null);
        _ember['default'].run.next(function () {
          document.body.scrollTop = _this.get('lastScroll');
        });
      } else {
        this.set('lastScroll', document.body.scrollTop);
        this.set('modalVisible', true);
        document.body.scrollTop = 0;
      }
    }
  });
});